<template>
  <section id="push-notifications">
    <b-row>
      <b-col cols="12">
        <h1>{{ $t("Notifications") }}</h1>
        <h5 class="text-primary">
          {{ $t("notificationPush.subtitle") }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="match-height mt-3">
      <b-col lg="12">
        <b-overlay
          variant="transparent"
          :show="show"
        >
          <b-card no-body>
            <b-card-body>
              <b-card-title>{{ $t('Push Notifications') }}</b-card-title>
              <validation-observer
                ref="simpleRules"
                tag="form"
              >
                <b-row>
                  <b-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="message"
                      rules="required"
                    >
                      <b-form-group
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <label for="message">{{
                          $t("notificationPush.text")
                        }}</label>
                        <b-input-group>
                          <b-form-input
                            id="message"
                            v-model="notificationText"
                            maxlength="150"
                            type="text"
                            autocomplete="new-password"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('notificationPush.text')"
                          />
                        </b-input-group>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- <b-col>
                    <b-form-group cols="2" label="¿Envíar notificación con enlace?">
                      <b-form-checkbox switch v-model="isUrl" />
                    </b-form-group>
                  </b-col>
                  <b-col v-if="isUrl" cols="10">
                    <validation-provider #default="{ errors }" name="link" rules="required">
                      <b-form-group invalid-feedback="Enlace requerido" :state="errors.length > 0 ? false : null">
                        <label for="link">Enlace web de la notificación</label>
                        <b-input-group>
                          <b-form-input id="link" v-model="urlLink" maxlength="220" type="text"
                            autocomplete="new-password" :state="errors.length > 0 ? false : null"
                            placeholder="Enlace web" />
                        </b-input-group>
                      </b-form-group>
                    </validation-provider>
                  </b-col> -->
                  <!-- BUTTONS ZONE -->
                  <b-col
                    cols="12"
                    class="mt-2 d-flex justify-content-around"
                  >
                    <b-button
                      :disabled="disabled"
                      variant="primary"
                      @click="validationForm(false)"
                    >
                      <span v-if="showUsers">{{
                        $t("notificationPush.send")
                      }}</span>
                      <span v-else>{{ $t("notificationPush.send1") }}</span>
                    </b-button>
                    <b-button
                      :disabled="disabledDemo"
                      variant="primary"
                      @click="validationForm(true)"
                    >
                      <span>Enviar solo a usuarios no registrados</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="info"
                      @click="changeShowStatus"
                    >
                      {{ $t("notificationPush.select") }}
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="showUsers"
                    cols="12"
                    class="mt-3"
                  >
                    <b-table
                      ref="selectableTable"
                      selectable
                      multi
                      :striped="true"
                      :no-border-collapse="true"
                      :items="items"
                      :fields="columns"
                      responsive
                      @row-selected="onRowSelected"
                    >
                      <template #cell(status)="status">
                        <template v-if="status.item.status">
                          <b-badge variant="light-success">
                            {{ $t("dataGeneric.active") }}
                          </b-badge>
                        </template>
                        <template v-else>
                          <b-badge variant="light-danger">
                            {{ $t("dataGeneric.inactive") }}
                          </b-badge>
                        </template>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-pagination
      v-if="showUsers"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      size="lg"
      @input="getFinalUsers"
    />
  </section>
</template>
<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardTitle,
  BPagination,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BOverlay,
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, cleanUsername } from '@/store/functions'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BBadge,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BCardTitle,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BPagination,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      disabled: true,
      notificationText: null,
      currentPage: 1,
      perPage: 10,
      showUsers: false,
      show: false,
      urlLink: null,
      rows: 0,
      isLoading: false,
      disabledDemo: true,
      hasMore: false,
      isUrl: false,
      columns: [
        { key: 'user_name', sortable: true, label: 'Nombre de usuario' },
        { key: 'email', sortable: true },
        { key: 'last_login', label: 'Último login' },
        { key: 'status', sortable: true, label: 'Status' },
      ],
      items: [],
      selected: [],
      userData: getUserData(),
    }
  },
  watch: {
    notificationText() {
      if (!this.showUsers) {
        if (
          this.notificationText != null
          && this.notificationText.trim() !== ''
        ) {
          this.disabled = false
          this.disabledDemo = false
        } else {
          this.disabled = true
          this.disabledDemo = true
        }
      } else if (
        this.notificationText != null
          && this.notificationText.trim() !== ''
      ) {
        // eslint-disable-next-line no-unused-expressions
        this.selected.length === 0
          ? (this.disabled = true)
          : (this.disabled = false)
      } else {
        this.disabled = true
      }
    },
    selected() {
      if (!this.showUsers) {
        if (
          this.notificationText != null
          && this.notificationText.trim() !== ''
        ) {
          this.disabled = false
          this.disabledDemo = false
        } else {
          this.disabled = true
          this.disabledDemo = true
        }
      } else if (
        this.notificationText != null
          && this.notificationText.trim() !== ''
      ) {
        // eslint-disable-next-line no-unused-expressions
        this.selected.length === 0
          ? (this.disabled = true)
          : (this.disabled = false)
      } else {
        this.disabled = true
      }
    },
    showUsers() {
      if (!this.showUsers) {
        if (
          this.notificationText != null
          && this.notificationText.trim() !== ''
        ) {
          this.disabled = false
          this.disabledDemo = false
        } else {
          this.disabled = true
          this.disabledDemo = true
        }
      } else {
        this.disabledDemo = true
        if (
          this.notificationText != null
          && this.notificationText.trim() !== ''
        ) {
          // eslint-disable-next-line no-unused-expressions
          this.selected.length === 0
            ? (this.disabled = true)
            : (this.disabled = false)
        } else {
          this.disabled = true
        }
      }
    },
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);

  },
  methods: {

    cleanUsername,
    validationForm(demoUsers = false) {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.disabled = false
            this.sendPushNotification(demoUsers)
          } else {
            this.disabled = false
            reject()
          }
        })
      })
    },
    sendPushNotification(demoUsers) {
      let query = ''
      const userData = getUserData()
      // Uno o varios usuarios
      if (!this.isUrl) {
        if (this.showUsers) {
          const selectedUsers = this.selected.map(item => item.id)
          query = `mutation {
          sendPush(
            text: "${this.notificationText}", users: [${selectedUsers}], clientId: "${userData.profile.client.id}",
            demoUsers:${demoUsers}){
              result
            }
          }`
        } else {
        // Todos los usuarios de la plataforma
          query = `mutation {
          sendPush(
            text: "${this.notificationText}", clientId: "${userData.profile.client.id}",
            demoUsers:${demoUsers}){
              result
            }
          }`
        }
      } else if (this.showUsers) {
        const selectedUsers = this.selected.map(item => item.id)
        query = `mutation {
          sendPush(
            text: "${this.notificationText}",link:"${this.urlLink}", users: [${selectedUsers}], clientId: "${userData.profile.client.id}",
            demoUsers:${demoUsers}){
              result
            }
          }`
      } else {
        // Todos los usuarios de la plataforma
        query = `mutation {
          sendPush(
            text: "${this.notificationText}",link:"${this.urlLink}", clientId: "${userData.profile.client.id}",
            demoUsers:${demoUsers}){
              result
            }
          }`
      }

      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: 'Notificación enviada',
              icon: 'CoffeeIcon',
              variant: 'success',
              position: 'bottom-right',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: 'Error al enviar la notificación',
              icon: 'fas fa-times',
              variant: 'error',
              position: 'bottom-right',
            },
          })
        })
    },
    onRowSelected(items) {
      this.selected = items
    },
    changeShowStatus() {
      this.showUsers = !this.showUsers
      if (this.showUsers) this.getFinalUsers()
    },
    getFinalUsers() {
      this.isLoading = true
      this.show = true
      const userData = getUserData()
      const query = `
            query {
                allVusers(isActive:true,client:"${userData.profile.client.id}",
                devices:true,
                first:${this.perPage},
                offset:${this.perPage * (this.currentPage - 1)},) {
                    pageInfo {
                      hasNextPage
                    }
                    totalCount
                    edges {
                    node {
                        id
                        username
                        email
                        isActive
                        lastLogin
                        deviceSet {
                        totalCount
                        edgeCount
                        edges {
                          node {
                            id
                            idDevice
                          }
                        }
                      }
                    }
                    }
                }
            }
        `
      axios
        .post('', { query })
        .then(res => {
          messageError(res, this)
          this.rows = res.data.data.allVusers.totalCount

          let search = res.data.data.allVusers.edges
          search = search.map(item => {
            const prefix = this.cleanUsername(item.node.username)
            return {
              id: item.node.id,
              user_name: prefix,
              email: item.node.email,
              last_login: item.node.lastLogin,
              status: item.node.isActive,
            }
          })
          this.items = search

          this.isLoading = false
          this.show = false
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#push-notifications .card-body {
  padding: 1.3rem;
}

#push-notifications .card-body h4 {
  font-size: 1.285rem !important;
}

#push-notifications .card-title {
  font-weight: 500;
}
</style>
